<template>
  <v-container class="address-selector-view">
    <Breadcrumb :items="breadCrumbs" />
    <v-btn
      class="onboarding-back mb-3"
      color="primary"
      text
      link
      :to="{
        name: 'DeliveryServiceView'
      }"
    >
      <v-icon small>$arrowBack</v-icon>
      Torna alla scelta del servizio
    </v-btn>
    <AddressSelector
      :deliveryServiceId="deliveryServiceId"
      :isNotPopup="true"
      :fetchedCategory="category"
      @submit="goToTimeslotView"
    />
  </v-container>
</template>
<style lang="scss">
.address-selector-view {
  .address-selector {
    padding: 0px;
  }
}
</style>
<script>
import AddressSelector from "@/components/delivery/AddressSelector.vue";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
import { mapState } from "vuex";

export default {
  name: "AddressSelectorView",
  props: { deliveryServiceId: { type: String, required: false } },
  data() {
    return {};
  },
  components: { AddressSelector, Breadcrumb },
  computed: {
    breadCrumbs() {
      let pathArray = this.$route.path.split("/");
      pathArray.shift();
      const breadCrumbs = [{ href: "/spesa-online", text: "Homepage" }];
      // needed to handle the intermediary entries for nested vue routes
      let breadcrumb = "";
      // let lastIndexFound = 0;

      for (let i = 1; i < pathArray.length; ++i) {
        // from 1 to skip "Area personale"
        breadcrumb = `${breadcrumb}${"/"}${pathArray[i]}`;
        if (
          this.$route.matched[i] &&
          Object.hasOwnProperty.call(this.$route.matched[i], "meta") &&
          Object.hasOwnProperty.call(this.$route.matched[i].meta, "breadCrumb")
        ) {
          breadCrumbs.push({
            href: breadcrumb,
            // i !== 0 && pathArray[i - (i - lastIndexFound)]
            //   ? "/" + pathArray[i - (i - lastIndexFound)] + breadcrumb
            //   : breadcrumb,
            exact: false,
            disabled: i + 1 === pathArray.length,
            text: this.$route.matched[i].meta.breadCrumb || pathArray[i]
          });
          // lastIndexFound = i;
          // breadcrumb = "";
        }
      }
      return breadCrumbs;
    },
    ...mapState({
      category: ({ category }) => category.category
    })
  },
  methods: {
    goToTimeslotView(addressSetted) {
      if (addressSetted) {
        this.$router.push({
          name: "TimeslotSelectorView",
          query: {
            deliveryServiceId: this.deliveryServiceId,
            back: this.$route.query.back
          }
        });
      }
    }
  },
  created() {}
};
</script>
